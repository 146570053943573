import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {NotixService} from "./service/notix.service";
import {TriagemService} from "./service/triagem.service";
import {LoaderService} from "./service/loader.service";
import {BreadcrumbService} from "./service/breadcrumb.service";

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <p-blockUI [blocked]="loaderService.statusLoader" [autoZIndex]="false" [baseZIndex]="500000" >
      <app-spinner></app-spinner>
    </p-blockUI>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(public primengConfig: PrimeNGConfig,
              public loaderService: LoaderService,
              public breadcrumb: BreadcrumbService,
              public notix: NotixService) {
  }

  ngOnInit(): void {
    this.setTranslation();
    this.notix.createNotix();
    this.primengConfig.ripple = true;
  }

  ngOnDestroy() {
    this.breadcrumb.unsubscribeAll();
  }

  setTranslation(): void {
    this.primengConfig.setTranslation({
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      dateFormat: 'dd/mm/yy',
      emptyMessage: 'Sem Resposta',
    });
  }
}
